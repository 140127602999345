<template>
  <article
    class="property-listing"
    @click="isImageModalActive = !isImageModalActive"
  >
    <div
      class="image"
      v-bind:style="{
        backgroundImage:
          'url(' + propertyDetails.data.images[0].image.url + ')',
      }"
    ></div>
    <div class="listing-content">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h2 class="model-price">
              {{ formatPrice(propertyDetails.data.list_price) }}
            </h2>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div style="text-align: right">
              <p class="model-street">
                {{ propertyDetails.data.street_1 }}
                {{ propertyDetails.data.street_2 }}
              </p>
              <p class="model-rest">
                {{ propertyDetails.data.city }}
                {{ propertyDetails.data.state }},
                {{ propertyDetails.data.zip }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="model-details level is-mobile">
        <div class="level-item">
          <p class="has-text-centered">
            <em>Sqft:</em> <br />{{
              propertyDetails.data.sqft.toLocaleString(undefined, {
                minimumFractionDigits: 0,
              })
            }}
          </p>
        </div>
        <div class="level-item">
          <p class="has-text-centered">
            <em>Lot:</em> <br />{{
              propertyDetails.data.lot_size.toLocaleString(undefined, {
                minimumFractionDigits: 1,
              }) || "--"
            }}
            AC
          </p>
        </div>
        <div class="level-item">
          <p class="has-text-centered">
            <em>Property Type</em> <br />
            {{ propertyDetails.data.property_type || "--" }}
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    propertyDetails: Object,
  },
  data: () => {
    return {
      isImageModalActive: false,
    };
  },
  methods: {
    formatMiles(n) {
      if (n < 1e3) return n;
      if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
    },
    formatPrice(n) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.property-listing {
  background: #2a2f45;
  border: 1px solid hsl(39, 40%, 32%);
  display: flex;
  border-radius: 3px;
  transition: all 150ms ease-out;
  filter: saturate(70%);
  &:hover {
    cursor: pointer;
    filter: saturate(100%);
    border: 1px solid #9898ac !important;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 8px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
  .image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 225px;
    min-height: 145px;
  }
  .listing-content {
    padding: 0.5rem 1rem;
    flex-grow: 1;
  }
  .model-street {
    color: #ffffff;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }
  .model-rest {
    color: #c0c7d1;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0rem;
  }
  .model-price {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 1.5rem;
  }
  .model-details {
    width: 100%;
    font-size: 1.1rem;
    margin-top: 1rem;
    .level-item {
      border-right: 1px solid #a3acb9;
      &:last-of-type {
        border-right: none;
      }
    }
    .level-item p {
      color: rgb(240, 240, 240);
      font-weight: 400;
      font-size: 1rem;
    }
    em {
      color: #a3acb9;
    }
  }
}

@media only screen and (max-width: 600px) {
  .property-listing {
    flex-direction: column;
    width: 100%;
    .model-price {
      font-size: 2rem;
    }
    .level-right {
      margin-top: 0;
    }
  }
  .image {
    width: 100% !important;
  }
}
</style>
