<template>
  <main class="home">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container is-max-widescreen">
          <h1 class="title is-1 has-text-centered">Available Properties</h1>
          <p class="text">
            Our market knowledge of this area is unsurpassed. Our alliances with
            local government agencies, universities, chambers of commerce, board
            of Realtors, and the Wayne County Development Council assure our
            clients coverage of all transaction elements. Whether the project is
            an entire complex or a single building, land assemblage,
            build-to-suit, or tenant representation, we stand prepared to give
            the professional service required to accomplish the assignment for
            you.
          </p>
        </div>
      </div>
    </div>
    <br /><br /><br />
    <div class="container">
      <div class="columns is-multiline">
        <div v-if="inventoryCoordinates" class="column is-12 card-wrapper">
          <h1 class="title">Map View</h1>
          <hr />
          <div class="map">
            <MglMap
              :access-token="accessToken"
              :map-style="mapStyle"
              :center="center"
              :zoom="7.5"
              :pitch="60"
              :bearing="340"
              container="map-container"
            >
              <MglMarker
                v-for="(coords, index) in inventoryCoordinates"
                :key="index"
                :coordinates="coords"
                color="#d8af64"
              />
            </MglMap>
          </div>
          <br />
          <br />
        </div>
        <section v-if="properties" class="inventory column is-12">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h1 class="title">List View</h1>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item sort-container">
                <b-dropdown :triggers="['hover']" aria-role="list">
                  <template #trigger>
                    <b-button
                      label="Sort Listings"
                      type="is-primary"
                      icon-right="caret-down"
                      icon-pack="fas"
                    />
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="sortProperties('price-asc')"
                  >
                    Price Ascending
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="sortProperties('price-desc')"
                  >
                    Price Descending
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="sortProperties('sqft-asc')"
                  >
                    Sqft Ascending
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="sortProperties('sqft-desc')"
                  >
                    Sqft Descending
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <hr />
          <div class="columns is-multiline">
            <router-link
              v-for="property in properties.results"
              :key="property.data.hillview_internal_id"
              :to="{
                name: 'InventoryDetail',
                params: { id: property.data.hillview_internal_id },
              }"
              class="column is-6 card-wrapper"
            >
              <CardPropertyHorizontal :propertyDetails="property" />
            </router-link>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
// import { useLoadUsers, deleteUser } from '@/firebase'
import CardPropertyHorizontal from "@/components/CardPropertyHorizontal";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";

export default {
  name: "Browse",
  components: { CardPropertyHorizontal, MglMap, MglMarker },
  data: () => {
    return {
      properties: undefined,
      inventoryCoordinates: undefined,
      modal: false,
      accessToken:
        "pk.eyJ1IjoibXR3aGNyZSIsImEiOiJja3BnMDcwcnIwYjFsMnVvOTMxM2JhdmJjIn0.yKkEMUKcPRyti3ED3mOQUg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mtwhcre/ckpotrod00aef17s4x08mzua5", // your map style
      center: [-83.37736141151493, 42.48706224043698],
    };
  },
  created() {
    this.mapbox = Mapbox;
  },
  watch: {
    properties: function (val) {
      if (val) this.buildMap();
    },
  },
  mounted() {
    this.loadProperties();
  },
  methods: {
    async loadProperties() {
      this.properties = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "listing")
      );
    },
    buildMap() {
      const listings = this.properties.results;
      const coords = [];
      listings.forEach((listing) => {
        let listingCoords = [];
        listingCoords.push(
          listing.data.geopoint.longitude,
          listing.data.geopoint.latitude
        );
        coords.push(listingCoords);
      });
      this.inventoryCoordinates = coords;
      // .data.geopoint
    },
    sortProperties(sortType) {
      let properties = this.properties.results;
      if (sortType === "price-asc") {
        properties.sort(
          (a, b) =>
            parseFloat(a.data.list_price) - parseFloat(b.data.list_price)
        );
      } else if (sortType === "price-desc") {
        properties.sort(
          (a, b) =>
            parseFloat(b.data.list_price) - parseFloat(a.data.list_price)
        );
      } else if (sortType === "sqft-asc") {
        properties.sort(
          (a, b) => parseFloat(a.data.sqft) - parseFloat(b.data.sqft)
        );
      } else if (sortType === "sqft-desc") {
        properties.sort(
          (a, b) => parseFloat(b.data.sqft) - parseFloat(a.data.sqft)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 40rem;
}
.navbar-item,
.title {
  color: #d8af64 !important;
  font-family: "Playfair Display";
  letter-spacing: 0.1rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
.sort-container {
  color: white;
}
hr {
  background: hsl(39, 40%, 32%);
}
.select select {
  background: hsl(39, 40%, 32%) !important;
}

.map {
  height: 350px;
  width: 100%;
  border: 1px solid hsl(39, 40%, 32%);
  border-radius: 4px;
  overflow: hidden;
  .map-container {
    height: 300px;
    width: 100%;
  }
}
.filter-controls {
  border: 1px solid $neutralQuaternaryAlt;
  border-radius: 0px;
}
.card-body {
  padding: 0 !important;
}
.field {
  padding: 0.5rem;
  margin-bottom: 0;
}
.navbar {
  background: #0d101d;
}
.home {
  background: #1a1f36;
  min-height: 100vh;
  font-family: "Lato", sans-serif;
}
.text {
  color: #ffffffe3;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.05rem;
}
@media only screen and (max-width: 600px) {
  .text {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  .card-wrapper {
    padding: 2rem;
  }
}
</style>
